/* .header-left{
    width: calc(50vw - 250px - 210px);
}
.header-collapsed .header-left{
    width: calc(50vw - 80px - 210px);
}
.header-middle{
    width: 400px
}
.header-right{
    width: calc(50vw - 210px);
    padding: 0px 20px;
} */
.header-right{
    padding: 0px 20px;
}
.header-menu-toggle {
    padding: 0 24px;
    font-size: 20px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}
.header-menu-toggle:hover {
    color: #4caf50;
}
/* .header-title{
    font-size: 20px;
    font-weight: bold;
    color: #404388;
} */
.header-title1{
    font-size: 19px;
    font-weight: bold;
    color: #404388;
    /* text-align: center; */
}
.header-title2{
    margin-top: 2px;
    font-size: 13px;
}
.user-profile{
    position: relative;
    border-radius: 5px;
    padding: 7px 10px;
    padding-right: 35px;
    cursor: pointer;
}
.user-profile:hover{
    background-color: #f0f8ff;
}
.user-profile-icon{
    width: 34px;
    height: 34px;
    background-color: #E3F2FD;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 18px;
    color: #5f629a;
    /* border: 1px solid #5f629a; */
}
.user-profile-username{
    font-weight: bold;
    margin-bottom: 4px;
    color: #202474;
}
.user-profile-role{
    font-size: 12px;
    color: #646aa2;
}
.user-profile-caret{
    position: absolute;
    right: 10px;
    color: #8d93bc;
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 0px) and (max-width: 767px){
    .header-right{
        padding: 0px 10px;
    }
    .header-menu-toggle {
        padding: 0 18px;
    }
    .header-title2 {
        margin-top: 2px;
        font-size: 9px;
    }
    .user-profile{
        padding-right: 10px;
    }
    .user-profile-icon{
        margin-right: 0px;
    }
    .user-profile-username{
        display: none;
    }
    .user-profile-role{
        display: none;
    }
    .user-profile-caret{
        display: none;
    }
}