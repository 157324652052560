.sidemenu{
    overflow: auto ;
    height: 100vh;
    position: fixed !important;
    left: 0;
    top: 0;
    bottom: 0;
    /* border-right: 1px solid #eeeeee; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
    background-color: #1e4b78;
}
.sidemenu-logo {  
    height: 70px;
    overflow: hidden;
    background-color: aliceblue;
    border-bottom: 1px solid #eeeeee;
}
.sidemenu-logo  img{
    margin-top: 2px;
    height: 70%;
}
.sidemenu-shelf{
    padding: 10px;
    padding-bottom: 5px;
}
.sidemenu-menu{
    padding: 5px;
    height: calc(100vh - 70px - 50px);
}
.sidemenu-footer{
    height: 70px; 
    padding: 15px;
}
.sidemenu-copyright{
    font-size: 13px;
    text-align: center;
    color: #757575;
}