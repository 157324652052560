.page-header{
    border-bottom: 1px solid #eeeeee;
    padding: 15px 20px 15px;
    margin-bottom: 15px;
    background-color: #ffffff;
}
.page-header.small{
    border-bottom: unset;
    margin-bottom: 5px;
}
.page-title-container{
}
.page-title{
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
}
.page-subtitle{
    margin-top: 5px;
    font-size: 13px;
    line-height: 13px;
    color: #757575;
}
.page-header-action{
}

@media (min-width: 0px) and (max-width: 767px){
    .page-header-action{
        width: 100%;
        margin-top: 5px;
    }
}