.tbl-answer{
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 25px;
}
.tbl-answer-row{
    border-bottom: 1px solid #bdbdbd;
    padding: 10px;
    padding-right: 0px;
}
.tbl-answer-row-title{
    font-weight: bold;
    font-size: 14px;
    color: #616161;
    width: 210px;
    padding-right: 15px;
}
.questionnaire-content{
    text-align: justify;
}