.questionnaire-required{
    color: #f44336;
    margin: 0px 40px 15px 20px;
}
.questionnaire-body{
    margin: 0px 40px 0px 20px;
}
.question-container{
    font-size: 15px;
    margin-bottom: 0px;
}
.question-no{
    width: 25px;
}
.question-body{
    width: calc(100% - 25px);
}
.question-wrap{
    margin-bottom: 5px;
}
.question-text.required:after{
    content:" *";
    color: #f44336;
}
.question-info{
    font-size: 13px;
    color: #757575;
}
.question-guide{
    font-size: 13px;
    font-style: italic;
    color: #9e9e9e;
    margin-bottom: 5px;
}