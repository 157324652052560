.timeline-item{
    background-color: #fafafa;
    border-radius: 4px;
    padding: 4px 10px 1px;
    border: 1px solid #eeeeee;
}
.timeline-action{
    font-size: 13px;
    margin-bottom: 4px;
}
.timeline-action-lable{
    width: 80px;
}
.timeline-action-desc{
    width: calc(100% - 80px);
    padding-left: 7px;
    position: relative;
}
.timeline-action-desc::before{
    content: ":";
    position: absolute;
    left: 0;
}