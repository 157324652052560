
.agora-table-pagination{
    position: absolute;
    right: 20px;
    top: -63px;
    z-index: 99;
}
.agora-table-header{
    padding: 0px 20px;
}
.agora-table-header-search{
    margin: 0 0 15px 0;
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 0px) and (max-width: 767px){
    .agora-table-header{
        padding: 0px 20px;
    }
    .agora-table-pagination{
        position: unset;
        margin-top: unset !important;
        padding: 0px 20px;
    }
}