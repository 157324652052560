body {
    margin: 0;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 13px;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

/* ANTD CUSTOM */
.ant-badge.ant-badge-status .ant-badge-status-processing{
    color: #039be5;
    background-color: #039be5;
}
.ant-timeline .ant-timeline-item-head-blue {
    color: #039be5;
    border-color: #039be5;
}

/* Antd Form */
.ant-form-item{
    margin-right: 20px;
    margin-bottom: 20px;
}
.ant-form-item .ant-form-item-label{
    font-weight: bold;
    padding: 0 0 5px;
} 
.ant-form-item .ant-form-item-label >label .ant-form-item-optional{
    font-weight: 400;
}

/* Antd Table */
.ant-table-wrapper .ant-table{
    overflow: auto;
}
.agora-table.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child{
    border-start-start-radius: 0px;
}
.agora-table.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child{
    border-start-end-radius: 0px;
}
.agora-table.ant-table-wrapper .ant-table-thead >tr>th,
.agora-table.ant-table-wrapper .ant-table-thead >tr>td{
    background: #e8f5e9;
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-size: 12px;
    line-height: 13px;
    border-bottom: 2px solid #c8e6c9; 
}
.agora-table.ant-table-wrapper .ant-table-thead >tr>th:first-child{
    padding-left: 20px;
}
.agora-table.ant-table-wrapper .ant-table-thead >tr>th:last-child{
    padding-right: 20px;
}
.agora-table.ant-table-wrapper .ant-table-tbody>tr>td{
    padding: 13px 16px;
}
.agora-table.ant-table-wrapper .ant-table-tbody>tr>td:first-child{
    padding-left: 20px;
}
.agora-table.ant-table-wrapper .ant-table-tbody>tr>td:last-child{
    padding-right: 20px;
}
.agora-table.ant-table-wrapper .ant-table-tbody>tr>td.ant-table-cell-row-hover{
    /* background: #e8f5e9; */
}
.agora-table.ant-table-wrapper .ant-table-tbody>tr:nth-child(2n){
    /* background: #f6fff7; */
}
.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td{
    background: #f5f5f5;
}
.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected:hover >td{
    background: #eeeeee;
}

/* Antd Menu */
.ant-layout .ant-layout-sider{
    background-color: #1e4b78;
}
.ant-menu-dark{
    background-color: #1e4b78;
}
.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline{
    background: rgb(255 255 255 / 5%);
}
.ant-menu-dark .ant-menu-item-selected {
    /* background-color: rgb(200 230 201 / 80%); */
    /* color: #2e7d32; */
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title{
    height: 34px;
    line-height: 34px;
}
.ant-menu .ant-menu-item-group{
   padding-bottom: 10px;
}
.ant-menu .ant-menu-item-group-title{
    /* border-top: 1px solid #f5f5f5; */
    padding: 8px 0px 0px;
    margin: 0px 14px;
    color: #ffffff;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.5px;
}
.ant-menu-light .ant-menu-item-selected{
    color: #2e7d32;
}
.ant-menu-item[visible="false"]{
    display: none !important;
}

/* Antd button */
.ant-btn.ant-btn-sm{
    font-size: 12px;
    padding: 2px 8px
}
.table-btn.primary{
    border-color:#2e7d32;
    color: #2e7d32;
}
.table-btn.primary:hover{
    background-color:#e8f5e9;
    border-color:#2e7d32;
    color: #2e7d32;
}
.table-btn.default,
.table-btn.edit{
    border-color:#039be5;
    color: #039be5;
}
.table-btn.default:hover,
.table-btn.edit:hover{
    background-color:#e3f2fd;
    border-color:#039be5;
    color: #039be5;
}
.table-btn.danger,
.table-btn.delete{
    border-color:#d32f2f;
    color: #d32f2f;
}
.table-btn.danger:hover,
.table-btn.delete:hover{
    background-color:#ffebee;
    border-color:#d32f2f;
    color: #d32f2f;
}

/* Antd descriptions */
.ant-descriptions .ant-descriptions-view {
    border-radius: 0px;
}
.ant-descriptions .ant-descriptions-item{
    padding: 0px 20px;
}
.ant-descriptions .ant-descriptions-item-label{
    font-weight: bold;
    width: 145px;
    position: relative;
    color: #616161;
}
.ant-descriptions .ant-descriptions-item-label::after{
    position: absolute;
    right: 0px;
}
/* .ant-descriptions.ant-descriptions-bordered .ant-descriptions-item-label{
    background-color: #e8f5e9;
} */
.ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, 
.ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content{
    padding: 7px 20px;
}
.ant-upload-wrapper .ant-upload-drag{
    background-color: #ffffff;
    /* border: 1px solid #e0e0e0; */
}

.ant-tabs >.ant-tabs-nav{
    padding: 0px 20px;
    margin-bottom: 0px;
}
.ant-form-item .ant-form-item-control-input{
    min-height: unset;
}
.ant-input-disabled,
.ant-input[disabled]{
    color: unset;
}
.ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper[disabled]{
    color: unset;
}
.ant-checkbox-disabled+span{
    color: unset;
}
.ant-radio-wrapper-disabled{
    color: unset;
}
.ant-picker .ant-picker-input >input-disabled,.ant-picker .ant-picker-input >input[disabled]{
    color:unset;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    color:unset;
}
.ant-radio-button-wrapper-disabled:first-child, .ant-radio-button-wrapper-disabled:hover{
    color:unset;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked{
    color:#ffffff;
    background-color: #4caf50;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-label{
    width: calc(40% - 12px);
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail, 
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head, 
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, 
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom{
    inset-inline-start: 40%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content, 
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content, 
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content{
    inset-inline-start: calc(40% - 4px);
    width: calc(60% - 12px);
}

@font-face {
    font-family: "Poppins";
    src: url("./fonts/Poppins-Thin.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./fonts/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./fonts/Poppins-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "Poppins";
    src: url("./fonts/Poppins-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}