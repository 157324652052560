.form-section{
    padding: 10px 0px 0px;
}
.form-header{
    padding: 5px 20px 4px;
    margin-bottom: 15px;
    background-color: #e8f5e9;
    border-bottom: 2px solid #c8e6c9;
}
.form-header-title{
    font-weight: bold;
    line-height: 1.5;
}
.form-body{
    padding: 0px 20px;
    margin-right: -15px;
}